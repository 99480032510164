import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AddTaskRequest } from 'src/app/shared/models/addTaskRequest.model';
import { TaskService } from 'src/app/shared/services/task/task.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit {
  @Output()
  public closeForm: EventEmitter<void> = new EventEmitter();

  public addTaskForm: FormGroup;

  private uid: string;
  private subscriptions: Subscription[] = [];

  constructor(private taskService: TaskService) { }

  public ngOnInit(): void {
    this.uid = JSON.parse(localStorage.getItem('user')).uid;


    this.addTaskForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl(''),
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public createTask(): void {
    if (this.addTaskForm.invalid) {
      return
    }

    let task: AddTaskRequest = this.addTaskForm.getRawValue();
    task.uid = this.uid;

    this.taskService.addNewTask(task);
    this.addTaskForm.reset();
    this.closeForm.emit();
  }

  public onCancel(): void {
    this.closeForm.emit();
  }

}
