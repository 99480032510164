import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { AddTaskRequest } from '../../models/addTaskRequest.model';
import { Task } from '../../models/task.model';
import { taskOrderChange } from '../../models/taskOrderChange.model';
import { ToggleTaskRequest } from '../../models/toggleTaskRequest.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  public taskListSource: BehaviorSubject<Task[]> = new BehaviorSubject([]);
  public taskList$ = this.taskListSource.asObservable();

  constructor(private afs: AngularFirestore) { }

  public addNewTask(task: AddTaskRequest): void {
    const newId = this.afs.createId();
    const taskRef: AngularFirestoreDocument<Task> = this.afs.doc(`tasks/${newId}`);

    const newTask: Task = {
      userUid: task.uid,
      taskUid: newId,
      title: task.title,
      description: task.description,
      isComplete: false,
      order: this.taskListSource.value.length === 0 ? 0 : this.taskListSource.value.sort((a, b) => b.order - a.order)[0].order + 1,
      dateCreate: new Date().toUTCString(),
      dateCompleted: null
    };

    taskRef.set(newTask, {merge: true});
  }

  public toggleTask(request: ToggleTaskRequest): void {
    const taskRef: AngularFirestoreDocument<Task> = this.afs.doc(`tasks/${request.taskId}`);

    taskRef.ref.get().then(task => {
      let updatedTask: Task = task.data();
      updatedTask.isComplete = request.status;

      if (request.status) {
        updatedTask.dateCompleted = new Date().toUTCString();
      } else {
        updatedTask.dateCompleted = null;
      }

      taskRef.set(updatedTask, {merge: true});
    });
  }

  public changeOrder(request: taskOrderChange): void {
    let batch = this.afs.firestore.batch();

    request.tasks.forEach(x => {
      const ref = this.afs.firestore.collection('tasks').doc(x.taskUid);
      batch.update(ref, x);
    });

    batch.commit();
  }

  public updateTask(request: Task): void {
    const taskRef: AngularFirestoreDocument<Task> = this.afs.doc(`tasks/${request.taskUid}`);

    taskRef.update(request);
  }
}
