import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-auth-actions',
  templateUrl: './auth-actions.component.html',
  styleUrls: ['./auth-actions.component.scss']
})
export class AuthActionsComponent implements OnInit {
  public mode: string;
  public email: string;
  public resetEmail: FormGroup;
  public showPasswordErrors: boolean = false;
  public showPass: boolean = false;
  public showPassConfirmation: boolean = false;

  private actionCode: string;

  constructor(public router: Router, public activateRoute: ActivatedRoute, private authService: AuthService) { }

  public ngOnInit(): void {
    this.activateRoute.queryParams.subscribe(params => {
        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        this.handleAuth();
    });

    this.resetEmail = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
  }

  public resetPassword(): void {
    if (this.resetEmail.invalid || this.resetEmail.controls['password'].value !== this.resetEmail.controls['confirmPassword'].value) {
      this.showPasswordErrors = true;
      return;
    }

    this.showPasswordErrors = false;

    this.authService.resetPassword(this.actionCode, this.resetEmail.controls['password'].value, this.email);
  }

  private handleAuth(): void {
    switch (this.mode) {
      case 'verifyEmail':
        this.authService.verifyEmail(this.actionCode);
        break;
      case 'resetPassword':
        this.authService.verifyPassReset(this.actionCode).then((email) => {
          this.email = email;
        });
    }
  }

}
