import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public resetForm: FormGroup;

  constructor(private authService: AuthService, private snackBar: MatSnackBar) { }

  public ngOnInit(): void {
    this.resetForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  public reset(): void {
    if (this.resetForm.invalid) {
      return
    }

    this.authService.forgotPassword(this.resetForm.controls['email'].value).then(() => {
      this.snackBar.open('Password reset email sent, check your inbox', '', {
        duration: 3000,
      });
    })
    .catch(() => {
      this.snackBar.open('Something went wrong, please check the email is correct or try again later.', '', {
        duration: 5000,
      });
    });
  }

}
