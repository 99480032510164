import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss']
})
export class AddButtonComponent implements OnInit {
  @Output()
  public buttonClicked: EventEmitter<void> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
  }

  public onButtonClick(): void {
    this.buttonClicked.emit();
  }

}
