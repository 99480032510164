<form [formGroup]="resetForm" class="reset__wrapper">
  <div class="reset">
    <div class="reset__title">
      Reset Password
    </div>
    <p class="label">Please enter your email address to request a password reset.</p>
    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input formControlName="email" type="username" matInput />
      <mat-error *ngIf="resetForm.invalid">You must put a valid email address.</mat-error>
    </mat-form-field>
    <button (click)="reset()" mat-raised-button>Reset Password</button>
    <p class="label link" routerLink="/sign-in">Return to Log In?</p>
  </div>
</form>
