import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { RouterModule } from '@angular/router';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { MaterialModule } from '../shared/components/material/material.module';

@NgModule({
  declarations: [MainComponent, GlobalHeaderComponent],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule.forChild([]),
  ],
  exports: [MainComponent, GlobalHeaderComponent]
})
export class CoreModule { }
