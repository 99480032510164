import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Task } from 'src/app/shared/models/task.model';
import { TaskService } from 'src/app/shared/services/task/task.service';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss']
})
export class ViewTaskComponent implements OnInit {
  @Input()
  public task: Task;

  @Output()
  public close: EventEmitter<void> = new EventEmitter();

  public edit: boolean = false;
  public editTask: FormGroup;

  constructor(private taskService: TaskService) { }

  public ngOnInit(): void {

  }

  public onClose(): void {
    this.close.emit();
  }

  public onEdit(): void {
    this.editTask = new FormGroup({
      title: new FormControl(this.task.title, [Validators.required]),
      description: new FormControl(this.task?.description)
    });

    this.edit = true;
  }

  public cancelEdit(): void {
    this.editTask.reset();
    this.edit = false;
  }

  public updateTask(): void {
    if (this.editTask.invalid) {
      return;
    }

    let updateTask = this.task;

    updateTask.title = this.editTask.controls['title'].value;
    updateTask.description = this.editTask.controls['description'].value;

    this.taskService.updateTask(updateTask);
    this.onClose();
  }

}
