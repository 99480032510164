<form [formGroup]="signUpForm" class="sign-up__wrapper">
  <div class="sign-up">
    <div class="sign-up__title">Sign Up</div>
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" type="text" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input formControlName="email" type="username" matInput />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input [type]="showPass ? 'text' : 'password'" matInput formControlName="password">
      <mat-icon class="show-pass" matSuffix (click)="showPass = !showPass">remove_red_eye</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Confirm Password</mat-label>
      <input [type]="showPassConfirmation ? 'text' : 'password'" matInput formControlName="passwordConfirmation">
      <mat-icon class="show-pass" matSuffix (click)="showPassConfirmation = !showPassConfirmation">remove_red_eye</mat-icon>
    </mat-form-field>
    <button (click)="signUp()" mat-raised-button>Sign Up</button>
    <!-- <p class="label">Or</p> -->
    <!-- <button (click)="signUpWithGoogle()" mat-raised-button>Continue with Google</button> -->
    <p class="label">Already have an account? <span class="link" routerLink="/sign-in">Log In</span></p>
  </div>
</form>
