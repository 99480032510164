import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public signUpForm: FormGroup;
  public showPass: boolean = false;
  public showPassConfirmation: boolean = false;

  constructor(private authService: AuthService) { }

  public ngOnInit(): void {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      passwordConfirmation: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });
  }

  public signUp(): void {
    if (this.signUpForm.invalid || this.signUpForm.controls['password'].value !== this.signUpForm.controls['passwordConfirmation'].value) {
      return;
    }

    this.authService.signUp(this.signUpForm.controls['email'].value, this.signUpForm.controls['password'].value, this.signUpForm.controls['name'].value);
  }

  public signUpWithGoogle(): void {
    this.authService.authLogin();
  }

}
