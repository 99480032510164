import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './core/components/main/main.component';
import { CoreModule } from './core/core.module';
import { AuthActionsComponent } from './features/containers/auth/containers/auth-actions/auth-actions.component';
import { DisplayTasksComponent } from './features/containers/display-tasks/container/display-tasks.component';
import { ForgotPasswordComponent } from './features/containers/login/containers/forgot-password/forgot-password.component';
import { LoginComponent } from './features/containers/login/containers/login/login.component';
import { SignUpComponent } from './features/containers/login/containers/sign-up/sign-up.component';
import { VerifyEmailComponent } from './features/containers/login/containers/verify-email/verify-email.component';
import { ActionGuard } from './shared/guards/actions-guard/action.guard';
import { AuthGuard } from './shared/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'tasks'
      },
      {
        path: 'tasks',
        pathMatch: 'full',
        component: DisplayTasksComponent,
        loadChildren: () => import('./features/containers/display-tasks/display-tasks.module').then(m => m.DisplayTasksModule),
        canActivate: [AuthGuard]
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('./features/containers/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register-user',
    component: SignUpComponent,
    loadChildren: () => import('./features/containers/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    loadChildren: () => import('./features/containers/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    loadChildren: () => import('./features/containers/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'auth',
    component: AuthActionsComponent,
    loadChildren: () => import('./features/containers/auth/auth.module').then(m => m.AuthModule),
    canActivate: [ActionGuard]
  },
  {
    path: '**',
    redirectTo: 'tasks'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CoreModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
