<div class="view-task">
  <div *ngIf="!edit else editView;" class="task-wrapper">
    <div class="view-task__header" mat-dialog-header>
      <div class="inline-control">
        <mat-icon (click)="onEdit()" *ngIf="!task?.isComplete && !edit" class="edit">edit</mat-icon>
        <p class="view-task__header--title">{{ task?.title }} <span *ngIf="task?.isComplete"> - Completed</span></p>
      </div>
      <mat-icon class="cancel-button" (click)="onClose()">close</mat-icon>
    </div>
    <hr class="header-divide">
    <div class="view-task__description">
      {{ task?.description }}
    </div>
  </div>

  <ng-template #editView>
    <form [formGroup]="editTask">
      <div class="view-task__header edit-task__header" mat-dialog-header>
        <mat-form-field appearance="outline">
          <mat-label>Title</mat-label>
          <input formControlName="title" type="text" matInput>
          <mat-error *ngIf="editTask.invalid">The task must have a title</mat-error>
        </mat-form-field>
        <mat-icon class="cancel-button" (click)="onClose()">close</mat-icon>
      </div>
      <hr class="header-divide">
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea formControlName="description" class="task-description" matInput></textarea>
      </mat-form-field>
      <div class="actions">
        <button mat-raised-button color="warn" (click)="cancelEdit()">Cancel</button>
        <button mat-raised-button (click)="updateTask()">Save</button>
      </div>
    </form>
  </ng-template>
</div>
