<form [formGroup]="addTaskForm" class="add-task">
  <div class="add-task__header" mat-dialog-title>
    <p>Add Task</p>
    <mat-icon class="cancel-button" (click)="onCancel()" mat-icon-button>close</mat-icon>
  </div>
    <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea class="add-task__description" matInput formControlName="description"></textarea>
    </mat-form-field>
    <div class="add-task__buttons">
        <button mat-raised-button type="reset" color="warn">clear</button>
        <button mat-raised-button type="button" (click)="createTask()">submit</button>
    </div>
</form>
