import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewTaskComponent } from 'src/app/shared/components/view-task/view-task/view-task.component';
import { Task } from 'src/app/shared/models/task.model';
import { taskOrderChange } from 'src/app/shared/models/taskOrderChange.model';
import { ToggleTaskRequest } from 'src/app/shared/models/toggleTaskRequest.model';
import { TaskService } from 'src/app/shared/services/task/task.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  @Output()
  public addTask: EventEmitter<void> = new EventEmitter();

  public showComplete: boolean = true;
  public displayedTasks: Task[] = [];

  private tasks: Task[] = [];

  constructor(private taskService: TaskService, private dialog: MatDialog, private sheet: MatBottomSheet) { }

  public ngOnInit(): void {
    this.taskService.taskList$.subscribe(tasks => {
      this.tasks = tasks.sort((a, b) => a.order - b.order);

      this.filterTasks();
    });
  }

  public toggleComplete(event: any, id: string): void {
    const request: ToggleTaskRequest = {
      taskId: id,
      status: event.checked
    };

    this.taskService.toggleTask(request);
  }

  public onAddTask(): void {
    this.addTask.emit();
  }

  public toggleShowCompleted(): void {
    this.showComplete = !this.showComplete;

    this.filterTasks();
  }

  public drop(event: CdkDragDrop<string[]>): void {
    const current = event.currentIndex;
    const previous = event.previousIndex;
    let tempTask = null;
    let tempTask2 = null;

    moveItemInArray(this.displayedTasks, previous, current);

    if (current - previous > 0) {
      for (let i = previous; i <= current; i++) {
        if (tempTask === null) {
          tempTask = this.displayedTasks[previous].order;
          this.displayedTasks[previous].order = this.displayedTasks[current].order;
        } else {
          tempTask2 = this.displayedTasks[i].order;
          this.displayedTasks[i].order = tempTask;
          tempTask = tempTask2;
        }
      }
    } else {
      for (let i = previous; i >= current; i--) {
        if (tempTask === null) {
          tempTask = this.displayedTasks[previous].order;
          this.displayedTasks[previous].order = this.displayedTasks[current].order;
        } else {
          tempTask2 = this.displayedTasks[i].order;
          this.displayedTasks[i].order = tempTask;
          tempTask = tempTask2;
        }
      }
    }

    const request: taskOrderChange = {
      tasks: this.displayedTasks
    };

    this.taskService.changeOrder(request);
  }

  public showTask(task: Task): void {
    const windowWidth = window.innerWidth;

    if (windowWidth > 769) {
      const taskDialog = this.dialog.open(ViewTaskComponent, {
        autoFocus: true,
        width: '600px',
        height: '600px',
      });

      taskDialog.componentInstance.task = task;

      taskDialog.componentInstance.close.subscribe(() => taskDialog.close());
    } else {
      const taskSheet = this.sheet.open(ViewTaskComponent);

      taskSheet.instance.task = task;

      taskSheet.instance.close.subscribe(() => taskSheet.dismiss());
    }
  }

  private filterTasks(): void {
    if (this.showComplete) {
      this.displayedTasks = this.tasks;
    } else {
      this.displayedTasks = this.tasks.filter(x => x.isComplete === false);
    }
  }

}
