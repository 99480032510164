import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Task } from 'src/app/shared/models/task.model';
import { TaskService } from 'src/app/shared/services/task/task.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  private userUid: string;

  constructor(private taskService: TaskService, private afs: AngularFirestore) { }

  public ngOnInit(): void {
    this.userUid = JSON.parse(localStorage.getItem('user')).uid;
    this.getAllTasks();
  }

  private getAllTasks(): void {
    this.afs.collection('tasks', ref => ref.where('userUid', '==', this.userUid)).valueChanges().subscribe((tasks: Task[]) => {
      this.taskService.taskListSource.next(tasks);
    });
  }

}
