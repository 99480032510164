import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SignIn } from 'src/app/shared/models/signIn.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public signInForm: FormGroup;
  public hasError: boolean = false;
  public signInError: string;
  public showPass: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private authService: AuthService) { }

  public ngOnInit(): void {
    this.subscriptions = [
      this.authService.loginResponseMessage$.subscribe(this.handleErrorResponse.bind(this))
    ];

    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public signIn(): void {
    if (this.signInForm.invalid) {
      return;
    }

    const request: SignIn = {
      email: this.signInForm.controls['email'].value,
      password: this.signInForm.controls['password'].value
    }

    this.authService.signIn(request.email, request.password);
  }

  public signInWithGoogle(): void {
    this.authService.authLogin();
  }

  private handleErrorResponse(message: string): void {
    if (message) {
      this.hasError = true;
      this.signInError = message;
    }
  }

}
