import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddTaskComponent } from 'src/app/shared/components/add-task/add-task/add-task.component';

@Component({
  selector: 'app-display-tasks',
  templateUrl: './display-tasks.component.html',
  styleUrls: ['./display-tasks.component.scss']
})
export class DisplayTasksComponent implements OnInit {

  constructor(public dialog: MatBottomSheet) { }

  public ngOnInit(): void {
  }

  public showAddForm(): void {
    const addTask = this.dialog.open(AddTaskComponent);

    addTask.instance.closeForm.subscribe(() => addTask.dismiss());
  }

}
