import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { routeOptions } from 'src/app/shared/configs/routeOptions.config';
import { navItem } from 'src/app/shared/models/navItem.model';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent implements OnInit {
  public appName: string = 'Task Manager';
  public user: User;
  public routeOptions: navItem[] = routeOptions;
  public currentPage: string;

  constructor(private authService: AuthService, private router: Router) {
    this.router.events.subscribe(route => {
      if (route instanceof NavigationEnd) {
        const url = route.url;

        if (url === '/tasks') {
          this.currentPage = 'Task List';
        }
      }
    });

    if (this.router.url === '/tasks') {
      this.currentPage = 'Task List';
    }
  }

  public ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  public logout(): void {
    this.authService.signOut();
  }

  public navigate(route: string): void {
    this.router.navigate([route]);
  }

  public onIconClick(): void {
    this.router.navigate(['tasks']);
  }

}
