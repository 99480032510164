<div class="task-list">
  <div class="task-filter">
    <mat-icon mat-icon-button [matMenuTriggerFor]="filterMenu" class="filter-icon" matTooltip="Filters">filter_list</mat-icon>
  </div>
  <div cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="task-list__item" *ngFor="let task of displayedTasks" cdkDrag>
        <mat-icon class="task-grip" cdkDragHandle>drag_indicator</mat-icon>
        <mat-checkbox (change)="toggleComplete($event, task.taskUid)" class="checkbox" [checked]="task.isComplete"></mat-checkbox>
        <div (click)="showTask(task)" class="task-title" [class.finished]="task.isComplete">{{ task.title }}</div>
        <div *ngIf="!!task.description" (click)="showTask(task)" class="task-description" [class.finished]="task.isComplete">- {{ task.description }}</div>
        <div class="task-placeholder" *cdkDragPlaceholder></div>
    </div>
  </div>
</div>

<ng-container *ngIf="displayedTasks.length === 0">
  <p (click)="onAddTask()" class="add-task--text">Add A New Task</p>
</ng-container>

<mat-menu #filterMenu="matMenu">
  <button mat-menu-item (click)="toggleShowCompleted()">{{showComplete ? "Hide" : "Show"}} Competed Tasks</button>
</mat-menu>
