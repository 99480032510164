import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, throwError } from 'rxjs';
import { User } from '../../models/user.model';
import { GoogleAuthProvider } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData: User;

  public loginResponseMessageSource: BehaviorSubject<string> = new BehaviorSubject(null);
  public loginResponseMessage$ = this.loginResponseMessageSource.asObservable();

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private router: Router) { }

  get isLoggedIn(): boolean {
    return !!JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).emailVerified;
  }

  public checkIfLoggedIn(): any {
    return this.afAuth.currentUser;
  }

  public signIn(email: string, password: string): any {
    return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((response) => {
      this.getUserData(response.user);
    })
    .catch(error => {
      let message;
      switch (error.message) {
        case 'Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).':
          message = 'Invalid Password.';
          break;
        case 'Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).':
          message = 'User not found.';
          break;
        case '':
          message = null;
          break;
        default:
          throwError(error.message);
          message = 'Uh Oh looks like we have a new task to complete. Something went wrong, please try again.';
      }

      this.loginResponseMessageSource.next(message);
    });
  }

  public authLogin(): any {
    return this.afAuth.signInWithPopup(new GoogleAuthProvider()).then(result => {
      this.setUserData(result.user).then(() => {
        this.router.navigate(['tasks']);
      });
    })
    .catch(error => {
      this.loginResponseMessageSource.next(error.message);
    });
  }

  public signUp(email: string, password: string, name: string): any {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then(result => {
      this.sendVerificationMail();
      this.setUserData(result.user, name)
    })
    .catch(error => console.log(error.message));
  }

  public sendVerificationMail(): any {
    return this.afAuth.currentUser
    .then(u => u.sendEmailVerification())
    .then(() => this.router.navigate(['verify-email']));
  }

  public forgotPassword(passwordResetEmail: string): any {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  public verifyEmail(actionCode: string): void {
    this.afAuth.checkActionCode(actionCode).then(() => {
      this.afAuth.applyActionCode(actionCode).then(() => {
        this.checkIfLoggedIn().then((user) => {
          let userRef = this.afs.doc(`users/${user.uid}`);

          userRef.ref.get().then(data => {
            let userData: any = data.data();
            userData.emailVerified = true;

            userRef.set(userData, {merge: true}).then(() => {
              localStorage.setItem('user', JSON.stringify(userData));
              this.router.navigate(['tasks']);
            });
          });
        });
      })
      .catch(error => console.log('error apply', error));
    })
    .catch(error => console.log('error check', error));
  }

  public verifyPassReset(actionCode: string): any {
    return this.afAuth.verifyPasswordResetCode(actionCode);
  }

  public resetPassword(actionsCode: string, newPass: string, email: string): void {
    this.afAuth.confirmPasswordReset(actionsCode, newPass).then(() => {
      this.signIn(email, newPass);
    })
  }

  private setUserData(user: User, name?: string): any {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: !!name ? name : user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    };

    localStorage.setItem('user', JSON.stringify(userData));

    return userRef.set(userData, {merge: true});
  }

  private getUserData(user: User): void {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    userRef.ref.get().then(doc => {
      const userData = doc.data();
      localStorage.setItem('user', JSON.stringify(userData));
      this.router.navigate(['tasks']);
    });
  }

  public signOut(): any {
    return this.afAuth.signOut().then(() => {
      localStorage.setItem('user', null);
      this.loginResponseMessageSource.next(null);

      this.router.navigate(['login']);
    })
  }
}
