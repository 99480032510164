export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyC_rQBtCD-K6Jzf0cS2blbOvWooSblVvuA",
    authDomain: "taskmanager-b4ed8.firebaseapp.com",
    projectId: "taskmanager-b4ed8",
    storageBucket: "taskmanager-b4ed8.appspot.com",
    messagingSenderId: "932414983033",
    appId: "1:932414983033:web:2f61702a28cbec7e3afda8",
    measurementId: "G-LL5BW9G9C0"
  }
};
