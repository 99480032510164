<div class="sign-in__wrapper">
  <form [formGroup]="signInForm" class="sign-in">
    <div class="sign-in__title">Welcome To Task Manager</div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input type="username" formControlName="email" matInput />
      <mat-error *ngIf="signInForm.controls['email'].errors">You must provide a valid email.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input [type]="showPass ? 'text' : 'password'" matInput formControlName="password">
      <mat-icon class="show-pass" matSuffix (click)="showPass = !showPass">remove_red_eye</mat-icon>
      <mat-error *ngIf="signInForm.controls['password'].errors">You must provide a password.</mat-error>
    </mat-form-field>
    <mat-error *ngIf="hasError">{{ signInError }}</mat-error>
    <button mat-raised-button (click)="signIn()">Log In</button>
    <button mat-raised-button (click)="signInWithGoogle()">
      Log in with Google
    </button>
    <span class="link" routerLink="/forgot-password">Forgot Password?</span>
    <span class="label">Don't have an account?<span class="link" routerLink="/register-user"> Sign Up</span></span>
  </form>
</div>
