<div class="verify__wrapper">
  <div class="verify">
    <div class="verify__title">Thank You for Registering</div>
    <div *ngIf="user?.email">
      <p class="label">We have sent a confirmation email to <strong>{{user.email}}</strong>.</p>
      <p class="label">Please check your email and click on the link to verify your email address.</p>
    </div>
    <button (click)="resend()" mat-raised-button>Resend Verification Email</button>
    <p class="link label" routerLink="/login" routerLinkActive="router-link-active" >Go back to Sign In.</p>
  </div>
</div>
