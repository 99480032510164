<div class="auth__wrapper">
  <form [formGroup]="resetEmail" class="auth">
    <img class="task-icon" src="assets/transparentTIcon.png" alt="Task Manager Icon">
    <container-element [ngSwitch]="mode">
      <div class="auth-body" *ngSwitchCase="'verifyEmail'">
        <p class="header-text">Thank you for verifying your email. Please wait to be redirected.</p>
        <mat-spinner diameter="100"></mat-spinner>
      </div>
      <div class="auth-body" *ngSwitchCase="'resetPassword'">
        <p class="header-text">Reset password for {{ email }}</p>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input [type]="showPass ? 'text' : 'password'" matInput formControlName="password">
          <mat-icon class="show-pass" matSuffix (click)="showPass = !showPass">remove_red_eye</mat-icon>
          <mat-error *ngIf="resetEmail.controls['password'].errors">You must provide a password.</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input [type]="showPassConfirmation ? 'text' : 'password'" matInput formControlName="confirmPassword">
          <mat-icon class="show-pass" matSuffix (click)="showPassConfirmation = !showPassConfirmation">remove_red_eye</mat-icon>
          <mat-error *ngIf="resetEmail.controls['confirmPassword'].errors">You must provide a password.</mat-error>
        </mat-form-field>
        <mat-error *ngIf="resetEmail.valid && showPasswordErrors">Please check that both passwords match.</mat-error>
        <button class="save-btn" mat-raised-button (click)="resetPassword()">Save</button>
      </div>
    </container-element>
  </form>
</div>
