import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'TaskManager';

  constructor(private swUpdate: SwUpdate) {}

  public ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
			// could be used if we need to push important hot fix
			this.swUpdate.available.subscribe(() => {
				if (confirm('New version available. Load New Version')) {
					this.swUpdate.activateUpdate().then(() => document.location.reload());
				}
			});

      //if we cannot pull an old cached version from the server we need to do a page reload to get latest
      this.swUpdate.unrecoverable.subscribe(event => {
        confirm(
          'An error occurred that we cannot recover from:\n' +
          event.reason +
          '\n\nPlease reload the page.'
        );
      });
		}
  }

}
